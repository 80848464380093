import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ReferenceComponent from "../components/reference/referenceComponent"
import * as teamPageStyles from "./team-page.module.scss"
import * as referencePageStyles from "./reference-page.module.scss"
import * as referenceComponentStyles from "../components/reference/reference-component.module.scss"

const ReferencePage = () => (
    <Layout>
      <Seo title="Reference" />
      <section className='container text-center' style={{marginTop: '8rem'}}>
        {/*
        TODO: Wait until Piquasso paper is published
        <div className={`row ${referencePageStyles.howToCite}`} style={{ marginBottom: '7rem' }}>
          <div className="col-md-12 d-flex flex-column align-items-left text-left">
            <header>
              <h2 className='mb-2'>How to cite Piquasso</h2>
              <p>If you are doing research using Piquasso, please cite our paper:</p>
            </header>
            <div className={referencePageStyles.citeGuide}>
              <p className={`mb-3 ${referencePageStyles.citeUs}`}>
                Thomas R. Bromley, Juan Miguel Arrazola, Soran Jahangiri, Josh
                Izaac, Nicolás Quesada, Alain Delgado Gran, Maria Schuld, Jeremy
                Swinarton, Zeid Zabaneh, and Nathan Killoran. “Applications of
                Near-Term Photonic Quantum Computers: Software and Algorithms”,
                arxiv:1912.07634 (2019).
              </p>
              <div className='d-flex flex-row justify-content-between'>
                <div className='d-flex flex-row'>
                  <button className={referenceComponentStyles.referenceBtn}>BibTeX</button>
                  <button className={referenceComponentStyles.referenceBtn}>EndNote</button>
                  <button className={referenceComponentStyles.referenceBtn}>RefMan</button>
                  <button className={referenceComponentStyles.referenceBtn}>RefWorks</button>
                </div>
                <button className={referencePageStyles.copyBtn}
                  onClick={() =>  navigator.clipboard.writeText(
                              'Thomas R. Bromley, Juan Miguel Arrazola, Soran Jahangiri, Josh\n' +
                    '                Izaac, Nicolás Quesada, Alain Delgado Gran, Maria Schuld, Jeremy\n' +
                    '                Swinarton, Zeid Zabaneh, and Nathan Killoran. “Applications of\n' +
                    '                Near-Term Photonic Quantum Computers: Software and Algorithms”,\n' +
                    '                arxiv:1912.07634 (2019).')}>
                  Copy to Clipboard
                </button>
              </div>

            </div>
            <p>
              We are always open for collaboration, and can be contacted at
              <span className={referencePageStyles.highlight}> piquassoquantum@gmail.com</span>
            </p>
          </div>
        </div>
        */}
        <div className="row mb-3">
          <div className="col-lg-12 d-flex flex-column align-items-left text-left">
            <header>
              <h2 className='mb-3'>Papers from the Community</h2>
              <div className={`mb-3 ${teamPageStyles.horizontalLine}`}/>
            </header>
          </div>
        </div>
        <div className="row" style={{ marginBottom: '7rem' }}>
          <div className="col-md-12 col-lg-10">
            <ReferenceComponent no={'1'}
                                reference={'Ágoston Kaposi, Zoltán Kolarovszki, Tamás Kozsik, Zoltán Zimborás, Péter Rakyta. Polynomial speedup in Torontonian calculation by a scalable recursive algorithm.'}
                                link={'arXiv:2109.04528'} href={"https://arxiv.org/abs/2109.04528"}/>
          </div>
        </div>
      </section>
    </Layout>
  )

export default ReferencePage
