import * as React from "react"
import { useState } from "react"
import { Modal } from "react-bootstrap"

import * as referenceComponentStyles from "./reference-component.module.scss"

const ReferenceComponent = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reference</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre style={{whiteSpace: "pre-wrap", margin: 0}}>
            <code style={{fontSize: ".75rem"}}>{`@article{favia2007bacteria,
  title={Bacteria of the genus Asaia stably associate with Anopheles stephensi, an Asian malarial mosquito vector},
  author={Favia, Guido and Ricci, Irene and Damiani, Claudia and Raddadi, Noura and Crotti, Elena and Marzorati, Massimo and Rizzi, Aurora and Urso, Roberta and Brusetti, Lorenzo and Borin, Sara and others},
  journal={Proceedings of the National Academy of Sciences},
  volume={104},
  number={21},
  pages={9047--9051},
  year={2007},
  publisher={National Acad Sciences}`}
              </code>
          </pre>
        </Modal.Body>
      </Modal>
      <div className={`d-flex flex-row text-left mb-3`}>
        <div className={`mr-3 ${referenceComponentStyles.referenceNo}`}>  {props.no}. </div>
        <div className='d-flex flex-column mb-3'>
          <p className={`mb-3 ${referenceComponentStyles.referenceItem}`}>
            {props.reference} &nbsp;
            <a href={props.href} target="_blank" className={referenceComponentStyles.referenceLink}>{props.link} </a>
          </p>
          {/*
          TODO: Add support for different citing formats for copying
          <div className='d-flex flex-row'>
            <button className={referenceComponentStyles.referenceBtn}
                    onClick={handleShow}>BibTeX</button>
            <button className={referenceComponentStyles.referenceBtn}
                    onClick={handleShow}>EndNote</button>
            <button className={referenceComponentStyles.referenceBtn}
                    onClick={handleShow}>RefMan</button>
            <button className={referenceComponentStyles.referenceBtn}
                    onClick={handleShow}>RefWorks</button>
          </div>
          */}
        </div>
      </div>
    </div>
  )
}

export default ReferenceComponent
